export const CONFIG = {
	IS_SERVER: true,
	API_URL: "https://charity.lbspb.ru/api/",
	SHOW_PERSONS: true,
	SHOW_LOCATIONS: true,
	BLUE_OR_GREEN_DESIGN: false,
	MAP_INDIVIDUAL_PLACEMARKS: false,
	MAP_GENERATOR: false,

	MAIN_PAGE: {
		HEADER: "Благотворительный Петербург",
		HEADER1: "Благотворительный",
		HEADER2: "Петербург"
	},
	
	PAGES_CARDS: {
		MAP_PAGE: {
		  HEADER: 'Карта',
		  TEXT: 'Интерактивная карта Санкт-Петербурга представляет возможность узнать адреса благотворительных учреждений и организаций, существовавших в Петербурге в XVIII – нач.XX вв., ознакомиться с их историей.',
		},
		ABOUT_PAGE: {
		  HEADER: 'О проекте',
		  TEXT: 'Данный проект, реализуемый Санкт-Петербургской государственной специальной центральной библиотекой для слепых и слабовидящих, представляет собой систематизированные материалы по истории благотворительности в Санкт-Петербурге. Разнообразие материалов (в том числе аудио, видео, фото и др.) позволяет воссоздать историческую перспективу развития благотворительного движения.',
		},
		ORGANIZATIONS_PAGE: {
		  HEADER: 'Организации',
		  TEXT: 'В этом разделе представлена информация о благотворительных организациях и обществах, действовавших в Санкт-Петербурге до 1917 года. Ведущие специалисты информационно-библиографического отдела библиотеки в своей работе опираются на исследования петербургских историков и краеведов, используют информацию из книг и других открытых источников, а также архивные документы.',
		},
		LOCATIONS_PAGE: {
		  HEADER: 'Адреса',
		  TEXT: 'В этом разделе представлена информация о благотворительных организациях и обществах, действовавших в Санкт-Петербурге до 1917 года. Ведущие специалисты информационно-библиографического отдела библиотеки в своей работе опираются на исследования петербургских историков и краеведов, используют информацию из книг и других открытых источников, а также архивные документы.',
		},
		PERSONS_PAGE: {
		  HEADER: 'Люди',
		  TEXT: 'На этой странице проекта собрана информация об исторических личностях, имеющих отношение к развитию благотворительности в Санкт-Петербурге и России.',
		},
	  },

	ABOUT_PAGE: [""],
	
	ORGANIZATION_PAGE: {
		INFO:"",
		LOCATIONS: "Адрес(а)",
		CATEGORIES: "",
		ALT_NAMES: "Другие названия",
		PERSONS: "Благотворители",
		ORGANIZATIONS: "Связи с другими организациями",
		LINKS: "Читаем по теме"
	},

	PERSON_PAGE: {
		INFO: "",
		LINKS:"Читаем по теме",
		PERSONS: "Связи с людьми",
		ORGANIZATIONS: "Связи с организациями",
	},

	LOCATION_PAGE: {
		INFO: "",
		DATES: "Даты постройки",
		ALT_NAMES: "Другие названия",
		PERSONS: "Архитекторы",
		LINKS: "Читаем по теме",
		ORGANIZATIONS: "С этим местом связаны"
	},

	CONTACTS:{
		ADDRESS: {
			TEXT:"Санкт-Петербург, ул.Стрельнинская, дом 11",
			LINK: "https://yandex.ru/maps/-/CDFfZ87-"
		},
		PHONE: {
			TEXT:"(812) 417-52-28",
			LINK:"tel:+78124175228"
		},
		EMAIL: {
			TEXT:"infor@gscbs.ru",
			LINK:"mailto:infor@gscbs.ru"
		},
		WEBSITE: {
			TEXT:"www.gbs.spb.ru",
			LINK:"https://www.gbs.spb.ru"
		}
	}
}